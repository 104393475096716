$white: rgba(255, 255, 255, 1);
$light: rgba(248, 248, 248, 1);
$lightBackground: rgba(240, 240, 240, 1);
$gray: rgba(150, 150, 150, 1);
$dark: #343434;

$red: #b42025;
$green: #417b5a;
$blue: rgba(20, 37, 76, 1);
$wsblue: #55d5e3;
$orange: #ffa300;

$font-family-logo: 'Josefin Sans', sans-serif;
$font-weight-logo: '400';

$alert-legnth: 7000;

:export {
  alertLength: $alert-legnth;
}
// bootstrap overrides
$primary: $blue;
$paleprimary: rgba($blue, 0.5);
$danger: $red;
$success: $green;
