@import '../../variables.scss';

.Footer {
  background-color: $light;
  margin: 0;
  padding: 0;
  padding-top: 0.5rem;
  border-top: $blue 1px solid;

  img {
    margin-right: 0.5rem;
  }
  .nav-item {
    font-size: 0.9rem;
  }
}
