@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
@import './variables.scss';
@import '~bootstrap/scss/bootstrap';

html,
body,
#root {
  height: 100%;
}
body {
  position: relative;
  height: 100%;
  background-color: $light;
  color: $blue;
  // padding: 1rem 2rem;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  padding-top: 4rem;
}
@media (max-width: 768px) {
  body {
    padding-top: 3rem;
  }
}

h1 {
  font-weight: 800;
  font-size: 2.4rem;
}
h2 {
  font-weight: 400;
  font-size: 1.8rem;
}
h3 {
  font-weight: 400;
  font-size: 1.4rem;
}
h4 {
  font-size: 1.2rem;
  font-weight: 700;
}
h5 {
  font-size: 1.1rem;
  font-weight: 700;
}
strong {
  font-weight: 600;
}

p,
button,
.btn {
  font-size: 1rem;
}
a {
  color: $blue;
  text-decoration: underline;
  text-decoration-color: $wsblue;

  &.btn {
    text-decoration: none;
  }
  &:hover {
    text-decoration-color: $blue;
  }
}

hr {
  border-style: dotted;
  border-color: $blue;
}

label {
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
}
.form-check label {
  font-size: 1rem;
  font-weight: 400;
  text-transform: none;
}
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type='file'] {
  width: 100%;
}

.row {
  margin: 0;
}

.block {
  margin-bottom: 1rem;
  background-color: $lightBackground;
  border-radius: 0.25rem;
  padding: 0.5rem;
}
.block-inner {
  margin: 0.5rem 0;
  padding: 0.5rem;
  background-color: $light;
  border-radius: 0.25rem;
}

.interface-button {
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
}
